import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { SignInComponent } from "./sign-in/sign-in.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModalModule } from "ngx-bootstrap/modal";
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SlugifyPipe } from "./pipes/slugify.pipe";
import { httpInterceptorProviders } from './interceptors';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { InternalServerComponent } from './error/internal-server/internal-server.component';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ResetPasswordComponent,
    SlugifyPipe,
    NotFoundComponent,
    InternalServerComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
  ],
  providers: httpInterceptorProviders,
  bootstrap: [AppComponent],
})
export class AppModule {}
