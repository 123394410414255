import { Injectable } from "@angular/core";
import * as Noty from "noty";

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  history: string[][] = [];

  constructor() {}

  push(type: Noty.Type, message: string) {
    this.history.push([type, message]);

    new Noty({
      type: type,
      text: message,
      timeout: 3000,
      progressBar: true,
      layout: "topRight",
      theme: "bootstrap-v4"
    }).show();
  }

  showHistory() {
    this.history.forEach(element => {
      console.log(element);
    });
  }

  clearHistory() {
    this.history = [];
  }
}
