import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ParametersInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let validkeys: Array<string> = [];

    for (const key of request.params.keys()) {
      let value = request.params.get(key);

      if (value == undefined || (typeof value == "string" && value.trim() == "")) {
        continue;
      }

      validkeys.push(key);
    }

    validkeys.sort((a, b) => a.localeCompare(b));

    let validParameters = new HttpParams();

    for (const key of validkeys) {
      validParameters = validParameters.append(key, request.params.get(key));
    }

    request = request.clone({ params: validParameters });

    return next.handle(request);
  }
}
