<div class="container-fluid">
  <div class="row">
    <div class="col-md-8 col-lg-8 col-xl-8 col-12 d-flex justify-content-center" style="margin-top: 100px">
      <img src="../../assets/image/screen.png" class="img-fluid" alt="Insilicall" style="width: 80%">
    </div>
    <div class="col-lg-3 col-xl-3">
      <img src="assets/image/logo.png" class="rounded mx-auto my-5 d-block" style="width: 82%; padding-right: 23px;" >

      <form
        [hidden]="displayedForm != 'sign-in'" #signInForm="ngForm" (ngSubmit)="onSignInFormSubmit(signInForm)" class="needs-validation" novalidate>
        <div class="form-group">
          <label for="email">Email</label> 
          <input ngModel #signInFormEmail="ngModel" [ngClass]="{ 'is-invalid': fieldHasError(signInFormEmail) }"
            type="email" id="signInFormEmail" name="email" class="form-control rounded-pill" placeholder="Ex.: your_email@email.com"/>
          
          <div *ngIf="fieldHasError(signInFormEmail)" class="invalid-feedback">
            <span *ngIf="signInFormEmail.errors.required">
              Email is required.
            </span>
            <span *ngFor="let error_message of signInFormEmail.errors.server_errors">
              {{ error_message }}.
            </span>
          </div>
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <input ngModel #signInFormPassword="ngModel" [ngClass]="{ 'is-invalid': fieldHasError(signInFormPassword) }" type="password"
            id="signInFormPassword" name="password" class="form-control rounded-pill" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" required minlength="6"/>
          
          <div *ngIf="fieldHasError(signInFormPassword)" class="invalid-feedback">
            <span *ngIf="signInFormPassword.errors.required">
              Password is required.
            </span>
            <span *ngIf="signInFormPassword.errors.minlength">
              Password should be minimum
              {{ signInFormPassword.errors.minlength.requiredLength }} characters.
            </span>
            <span *ngFor="let error_message of signInFormPassword.errors.server_errors">
              {{ error_message }}.
            </span>
          </div>
        </div>

        <div class="row" style="margin-top: 50px">
          <div class="col-md-6 col-12">
            <button type="submit" class="btn a-button__signIn btn-block rounded-pill" [disabled]="signInForm.form.invalid">
              Sign In
            </button>
          </div>
          <a class="col-sm-6 a-link mt-2" (click)="setDisplayedForm('reset-password')">             
              Forgot password?            
          </a>
        </div>

       <!-- 
        <div class="row" style="margin-top: 30px">
          <div class="col-sm-12">
            <button type="submit" class="btn a-button__signGoogle btn-block rounded-pill">
              SIGN IN WITH GOOGLE
            </button>
          </div>         
        </div>-->
      </form> 

      <form [hidden]="displayedForm != 'reset-password'" #resetPasswordForm="ngForm" (ngSubmit)="onResetPasswordFormSubmit(resetPasswordForm)"
        class="needs-validation bg-white rounded" novalidate style="padding-left: 15px">
        <div class="text-center" style="padding-top: 45px;">
          <h4>Reset Password</h4>

          <p class="text-muted h6 mt-4 mb-4">Enter your registered email address.</p>
        </div>

        <div class="form-group mt-2" style="padding-left: 36px; padding-right: 36px; padding-bottom: 15px;">
          <label for="email">Email</label>
          <input ngModel #resetPasswordFormEmail="ngModel" [ngClass]="{ 'is-invalid': fieldHasError(resetPasswordFormEmail) }"
            type="email" id="resetPasswordFormEmail" name="email" class="form-control rounded-pill" required />
          
          <div *ngIf="fieldHasError(resetPasswordFormEmail)" class="invalid-feedback">
            <span *ngIf="resetPasswordFormEmail.errors.required">
              Email is required.
            </span>
            <span *ngFor="let error_message of resetPasswordFormEmail.errors.server_errors">
              {{ error_message }}.
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <button
              type="submit" class="btn a-button__reset btn-block rounded-pill" [disabled]="resetPasswordForm.form.invalid">
              Reset
            </button>
          </div>
          <div class="col-sm-12 text-center a-link-reset mt-4">
            <span (click)="setDisplayedForm('sign-in')">
              Sign in?
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
