import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { tap, catchError, map, switchMap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { Experiment } from '../models/experiment';

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  
  constructor(private http: HttpClient, private router: Router, private notificationService: NotificationService, private session: SessionService) { }

  public resetPassword(data: { email: string }){
    return this.http.post(environment.apiURL + "/auth/send-reset-password-email", data, { headers: new HttpHeaders({ 'Skip-Authentication-Interceptor': 'true' }) });
  }

  public signIn(data: { email: string, password: string }): Observable<boolean> {
    return this.http.post<{}>(environment.apiURL + "/auth/sign_in", data, { headers: new HttpHeaders({ 'Skip-Authentication-Interceptor': 'true' }) }).pipe(
      switchMap((response:any) => {
        this.session.setData("access-token", response.token);
        this.session.setData("refresh-token", response.refreshToken);
  
        return this.session.load();
      })
    );
  }

  public signOut(message?: string): void {
    if (message) this.notificationService.push('warning', message);

    // Revokes the refresh token on the server-side
    if (this.session.getData("refresh-token")) this.http.get<[]>(`${environment.apiURL}/auth/sign_out`, { headers: new HttpHeaders({ 'Refresh-Token': this.session.getData("refresh-token") }) }).toPromise();

    this.session.destroy();

    this.router.navigateByUrl('sign-in');
  }

  public refreshToken(): Observable<boolean> {
    return this.http
      .get<[]>(environment.apiURL + "/auth/refresh", { headers: new HttpHeaders({ 'Refresh-Token': this.session.getData("refresh-token"), 'Skip-Authentication-Interceptor': 'true' }) })
      .pipe(
        map((response: any) => {
          this.session.setData("access-token", response.token);
          this.session.setData("refresh-token", response.refreshToken);

          return true;
        }),
        catchError(error => {
          this.signOut("Something went wrong. Please, sign-in again.");

          return throwError(error);
        })
      );
  }

  public authenticateExperiment(code: string): Observable<Experiment> {
    return this.http.get<Experiment>(`${environment.apiURL}/authenticate/experiment/${ encodeURIComponent(code) }`)
  }
}
