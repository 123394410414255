import { Injectable } from '@angular/core';

import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { JwtHelperService } from "@auth0/angular-jwt";
import { RequestCacheService } from './request-cache.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  // TODO: CRIAR UM ARRAY DE OBSERVABLES
  private _data: Array<any> = [];  

  public route_id = new BehaviorSubject(0);

  /* Getters */
  public getData(key: string): any {
    return key.endsWith("-token") ? localStorage.getItem(key) : this._data[key];
  }

  /* Setters */
  public setData(key: string, value: any) {
    key.endsWith("-token") ? localStorage.setItem(key, value) : this._data[key] = value;
  }

  constructor(private http: HttpClient, private requestCacheService: RequestCacheService) { }

  public isValid(): Observable<boolean> {
    if(this.getData("access-token")){
      return (this.getData("user")) ? of(true) : this.load();
    }else{
      return of(false);
    }
  }

  public load(): Observable<boolean> {
    // TODO: modificar a forma que a sessão é carregada usando new JwtHelperService().decodeToken( tokenAqui )

    return this.http.get(environment.apiURL + "/auth/session").pipe(
      map((response:any) => {
        this.setData("user", response.user);
        this.setData("company", response.user.company);
        this.setData("licenses", response.user.company.licenses);
        this.setData("products", response.products);

        return true;
      }),
      catchError(error => {
        return of(false)
      })
    );
  }

  public destroy(): void {
    // Removes all data from localStorage
    localStorage.clear();

    // Removes all data from browser memory
    this._data = [];

    this.requestCacheService.clear();
  }
}
