import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { RequestCacheService } from "../services/request-cache.service";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Continue if not cacheable
    if (
      request.method != "GET" ||
      request.headers == undefined ||
      !request.headers.has("Allow-Caching")
    ) {
      return next.handle(request);
    }

    request.headers.delete("Allow-Caching");

    const cachedResponse = this.cache.get(request.urlWithParams);

    return cachedResponse
      ? of(cachedResponse)
      : this.sendRequest(request, next);
  }

  private sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        // There may be other events besides the response
        if (event instanceof HttpResponse) {
          this.cache.set(request.urlWithParams, event);
        }
      })
    );
  }
}
