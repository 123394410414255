import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {
  private cache = new Map<string, [Date, HttpResponse<any>]>();

  constructor() { }

  get(key): HttpResponse<any> {
    const tuple = this.cache.get(key);

    if (!tuple) return undefined;

    const expires = tuple[0];
    const httpResponse = tuple[1];

    // Don't observe expired keys
    if (expires && expires.getTime() < new Date().getTime()) {
      this.cache.delete(key);

      return undefined;
    }

    return httpResponse;
  }

  set(key, value, secondsToExpires = 300) {
    const expires = new Date();

    expires.setSeconds(expires.getSeconds() + secondsToExpires);

    this.cache.set(key, [expires, value]);
  }

  clear() {
    this.cache = new Map<string, [Date, HttpResponse<any>]>();
  }
}
