import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthenticationInterceptor } from "./authentication.interceptor";
import { ParametersInterceptor } from "./parameters.interceptor";
import { CacheInterceptor } from "./cache.interceptor";
import { ErrorInterceptor } from './error.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ParametersInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
];
