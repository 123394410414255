import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SignInComponent } from "./sign-in/sign-in.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component'; 
import { NotFoundComponent } from './error/not-found/not-found.component';
import { InternalServerComponent } from './error/internal-server/internal-server.component';

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./protected-pages/protected-pages.module").then(
        mod => mod.ProtectedPagesModule
      )
  },
  { path: "sign-in", component: SignInComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "error",  component: InternalServerComponent },
  { path: "**",  component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
