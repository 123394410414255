import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { SessionService } from '../services/session.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private requestSemaphoreSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor(private auth: AuthenticationService, private session: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let skipInterceptor = false;

    if (request.headers && request.headers.has('Skip-Authentication-Interceptor')) {
      skipInterceptor = true;
      request.headers.delete('Skip-Authentication-Interceptor');
    } else {
      request = this.addAuthorization(request);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (skipInterceptor === false && error instanceof HttpErrorResponse && error.status === 401) return this.handle401Error(request, next);

        return throwError(error);
      })
    )
  }

  private addAuthorization(request: HttpRequest<any>): HttpRequest<any>{
    return !this.session.getData("access-token") ? request : request.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.session.getData("access-token") }`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.requestSemaphoreSubject.next(false);

      return this.auth.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          this.requestSemaphoreSubject.next(true);

          return next.handle(this.addAuthorization(request));
        })
      );
    } else {
      return this.requestSemaphoreSubject.pipe(
        filter(value => value == true),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthorization(request));
        })
      );
    }
  }
}
