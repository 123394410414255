
<div class="row a-logo">
    <div class="col-12 col-md-2 d-flex justify-content-center">
        <img class="a-img__logo" src="../../../assets/image/logo.png" />
    </div>
</div>
<div class="row" style="height: 100vh;"> 
    <div class="a-column__img col-md-8">
        <img class="a-img__ilustra" src="../../assets/image/erro404.png" />
    </div>

    <div class="col-md-4 a-column__responsive"> 
        <div class="row a-row__text">
            <div class="col-md-12 a-column__text">
                <span class="h2">
                    Opps! Lost in laboratory.
                </span> 
            </div> 
        </div>

        <div class="row a-row__text">
            <div class="col-md-12 a-column__text">
                <p class="a-description">
                    The page you're looking for isn't  found.
                    <br>
                    We suggest you back to home.
                </p> 
            </div>
        </div>

        <div class="row">
            <div class="a-column__button col-md-12">
                <a routerLink="/my-home" routerLinkActive="active" class="a-button__create-experiment" role="button">
                    Back to Home
                </a>
            </div>
        </div> 
    </div>   
</div>