import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../services/authentication.service";
import { NgForm } from "@angular/forms";
import { NotificationService } from "../services/notification.service";
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"]
})
export class SignInComponent implements OnInit {
  displayedForm: string;

  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    private session: SessionService
  ) {}

  ngOnInit() {
    this.displayedForm = "sign-in";
  }

  setDisplayedForm(formName: string) {
    this.displayedForm = formName;
  }

  fieldHasError(field) {
    return field.invalid && (field.dirty || field.touched);
  }

  onSignInFormSubmit(signInForm: NgForm) {
    console.log("SignInForm:", signInForm);

    this.authService.signIn(signInForm.value).subscribe(
      (response: boolean) => {
        console.log('signIn', response)
        if(response){      
          this.router.navigateByUrl(this.session.getData("redirectUrl") || '');
        }else{
          this.notificationService.push('warning', "Something went wrong. Please, try again again.")
        }
      },
      error_response => {
        console.log("error:", error_response);

        // errors triggered by the server
        if (Array.isArray(error_response.error)) {
          let server_errors = new Array();

          error_response.error.forEach(e => {
            if (server_errors[e.field] == undefined)
              server_errors[e.field] = [];

            server_errors[e.field].push(e.message);
          });

          for (const field in server_errors) {
            // reset sensitive data
            if (field == "password")
              signInForm.form.controls[field].setValue(null);

            signInForm.form.controls[field].setErrors({
              server_errors: server_errors[field]
            });
          }
        }
        // error triggered when it is not possible to connect to the server
        else {
          this.notificationService.push(
            "error",
            "Unable to connect to the server."
          );
        }
      }
    );
  }

  onResetPasswordFormSubmit(resetPasswordForm: NgForm) {
    this.authService.resetPassword(resetPasswordForm.value).subscribe(
      response => {
        this.notificationService.push('success', "Please check your email for more information.");
      },
      error_response => {
        // error triggered when it is not possible to connect to the server        
        this.notificationService.push(
          "error",
          "Unable to connect to the server."
        );
      }
    );
  }
}
