<div class="container-fluid" style="height: 630px">
    <div class="row d-flex justify-content-center mt-4">
        <div class="col-md-3 bg-white rounded" style="padding-bottom: 50px">
            <img src="assets/image/logo.png" class="rounded mx-auto my-2 d-block" style="width: 56%;">
      
            <p class="text-muted mt-4 mb-4">Please enter your new password.</p>
            <label>New Password</label>
            <input type="password" id="resetFormPassword" name="new-password" class="form-control rounded-pill" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" required minlength="6"/>
      
            <label class="mt-3">Confirm Password</label>
            <input type="password" id="confirmFormPassword" name="confirm-password" class="form-control rounded-pill" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" required minlength="6"/>
      
            <div class="row d-flex justify-content-center mt-4">
                <button type="submit" class="btn a-button__resetPassword btn-block rounded-pill">
                    Reset password
                  </button>
            </div> 
        </div>  
      </div>
      
</div>

